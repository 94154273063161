<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Notification logs')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
          :is-open="isOpenFilter"
          :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states
            }
          }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="events"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="eventsLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  text-color="dark"
                  class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                key="channel"
                :props="props"
              >
                <span v-if="props.row._embedded.channel">
                  {{ props.row._embedded.channel.name }}
                </span>
              </q-td>

              <q-td
                key="task"
                :props="props"
              >
                <span v-if="props.row._embedded.task">
                  {{ props.row._embedded.task.name }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="executed"
                :props="props"
              >
                {{ props.row.executed }}
              </q-td>

              <q-td
                key="executionTime"
                :props="props"
              >
                <span v-if="props.row.executionTime">
                  {{ $moment(props.row.executionTime.date).format(appOptions.formats.date) }}
                </span>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Components
import FiltersCollapse from './../../components/filters/FilterCollapse'
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'Events',
  components: {
    FiltersCollapse,
    Search
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Channel'),
          name: 'channel',
          align: 'left'
        },
        {
          label: this.$t('Task'),
          name: 'task',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Executed'),
          name: 'executed',
          align: 'left'
        },
        {
          label: this.$t('Execution Time'),
          name: 'executionTime',
          align: 'left'
        }
      ],
      isOpenFilter: false,
      stateColors: {
        pending: 'grey-4 text-dark',
        prepared: 'success',
        sent: 'info',
        delivered: 'info',
        error: 'danger',
        deleted: 'dark'
      },
      activatedFields: [
        'id',
        'created.from',
        'created.to',
        'state',
        'channel',
        'task'
      ],
      states: [
        { id: 'pending', title: 'pending' },
        { id: 'prepared', title: 'prepared' },
        { id: 'sent', title: 'sent' },
        { id: 'delivered', title: 'delivered' },
        { id: 'error', title: 'error' },
        { id: 'deleted', title: 'deleted' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'events',
      'eventsLoading',
      'totalEventsNumber',
      'eventsPage',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ' + this.totalEventsNumber)
        : this.$t('Filter')
    },
    filterBtnClass () {
      const basicStyles = 'btn btn-sm ml-2 mr-2 '
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? basicStyles + 'btn-success'
        : basicStyles + 'btn-white'
    },
    filters () {
      const actions = {
        gte: filter => ({ from: filter.value, to: null }),
        lte: filter => ({ from: null, to: filter.value }),
        between: filter => ({ from: filter.from, to: filter.to }),
        in: filter => (filter.values.map(id => this.states.find(x => x.id === id)))
      }

      return (this.serverParams.filter || []).reduce((acc, filter) => {
        const currFilterValue = typeof actions[filter.type] === 'function'
          ? actions[filter.type](filter)
          : filter.value

        return {
          ...acc,
          [filter.field]: currFilterValue
        }
      }, {})
    }
  },
  mounted () {
    this.pagination = {
      ...this.pagination,
      page: this.eventsPage,
      rowsNumber: this.totalEventsNumber
    }

    if (this.events.length > 0) {
      return
    }

    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadEvents'
    ]),
    ...mapMutations([
      'setEvent'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadEvents(this.serverParams)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    create () {
      this.$router.push('/settings/notifications/logs/entity')
    },
    onRowDblClick (row) {
      this.setEvent(row)
      this.$router.push('/settings/notifications/logs/entity/' + row.id)
    }
  }
}
</script>
